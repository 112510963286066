.selector-type-date div.col {
  cursor: pointer;
  border-right: 1px solid rgba(0,0,0,0.2);
  border-left: 1px solid rgba(0,0,0,0.2);
  padding: 1em 0;
}

.selector-type-date div.col:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-left: 0;
}


.selector-type-date div.col:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-right: 0;
}

.selector-type-date div.col.active{
  font-weight: bold;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}


@media screen and (max-width: 992px) {
  .selector-type-date div.col {
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2);
    border-left: 0;
    border-right: 0;
  }
  .selector-type-date div.col:first-child {
    border-radius: 0;
    border-top: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .selector-type-date div.col:last-child{
    border-radius: 0;
    border-bottom: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .selector-type-date div.col.active{
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 0;
    border-bottom: 0;
  }

}


.input-date .DayPickerInput input{
  text-align: center;
}

.input-date .row.InputFromToWrapper {
  margin: 0;
}

.input-date .DayPickerInput-OverlayWrapper {
  z-index: 100;
}

.InputFromToWrapper .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.InputFromToWrapper .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromToWrapper .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromToWrapper .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromToWrapper .DayPickerInput-Overlay{
  width: 550px;
}
.InputFromTo .DayPickerInput-Overlay {
  margin-left: -10.51rem;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -25rem;
}